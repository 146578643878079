import React, { useState } from 'react';
import toastr from 'toastr';
import "./contactus.css"

function ContactUs() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted', { email, subject, message });
  };

  const contactUsButton=()=>{
    toastr.error("Server not connected, no email sent")
  }

  return (
    <div className="card contact-us-container">
      <div className="card-icon">📧</div>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-us-form">
        <input
          type="email"
          id="email"
          placeholder="Your Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          id="message"
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <input
          type="text"
          id="subject"
          placeholder="Subject (optional but helpful)"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <button type="submit" onClick={contactUsButton}>Send</button>
      </form>
    </div>
  );
}

export default ContactUs;
