import React from "react";
import { useNavigate } from "react-router-dom";
import "./aboutme.css";
import AboutMeUpdatesApp from "./AboutMeUpdatesApp";

function Home() {
  const navigate = useNavigate();

  const goToHelpAndSupport = () => {
    navigate("/projects/helpandsupport");
  };

  const goToVideoTraining = () => {
    navigate("/projects/trainingvideo");
  };

  const goToAdminDashboard = () => {
    navigate("/projects/admindashboard");
  };

  const goToWorkoutData = () => {
    navigate("/projects/gymapp");
  };

  const goToBooksToScrape = () => {
    window.open("https://github.com/alexsoto562/BooksToScrape", "_blank");
  };

  const goToChessTournament = () => {
    window.open("https://github.com/alexsoto562/ChessManagement", "_blank");
  };

  return (
    <React.Fragment>
      <div className="about-card">
        <div className="about-text">
          <h1 className="about-heading">Alejandro Soto</h1>
          <h2>Full Stack Software Engineer</h2>
          <p>ACTIVE SECRET CLEARANCE | AIR FORCE VETERAN</p>
          <h3>Education</h3>
          <p>
            University of Maryland Global Campus, BS Computer Science
            <br />
            Anticipated Graduation Date: June 2024
          </p>

          <h3>Skills</h3>
          <p>
            <span className="skill-category">
              <strong className="skill-detail">Front-End:</strong> JavaScript,
              React, Formik, Yup, HTML5, CSS3, Bootstrap, JQuery, AJAX, Axios
            </span>
            <br />
            <span className="skill-category">
              <strong className="skill-detail">Back-End:</strong> SQL | C# |
              .NET | ASP.NET | Python | Flask | Requests | BS4
            </span>
            <br />
            <span className="skill-category">
              <strong className="skill-detail">Software/Tools:</strong> Visual
              Studio | Postman | SSMS | VS Code | Trello | Slack |GIT | GitHub |
              GIT BASH | Yarn | Azure | Google Analytics | Pip
            </span>
          </p>

          <h3>Professional Experience</h3>
          <h4>
            Assign Ref - Full Stack Software Engineer (Dec 2022 - Jul 2023)
            React, SQL, C#, .NET
          </h4>
          <ul className="professional-experience-list">
            <li>
              Directed the construction of an efficient{" "}
              <span onClick={goToAdminDashboard} className="clickable-text">
                Admin Dashboard
              </span>{" "}
              using React.js, effectively retrieving sensitive data such as
              revenue/sales figures, total number of football players, and
              referees through API calls. Ensured robust security measures by
              implementing secure routes for seamless authentication.
            </li>
            <li>
              Designed a user-friendly{" "}
              <span onClick={goToHelpAndSupport} className="clickable-text">
                Help and Support page
              </span>{" "}
              with React JSX, featuring an intuitive design and seamless
              navigation using React's routing. Enhanced the user experience
              with an extensive Frequently Asked Questions section for quick
              query resolution.{" "}
            </li>
            <li>
              I used React to build a user-friendly{" "}
              <span onClick={goToVideoTraining} className="clickable-text">
                Video Playback System
              </span>{" "}
              for Assign Ref, enabling referees to review training videos, past
              games, and take notes for skill improvement.
            </li>
            <li>
              Proficient in leveraging DOM manipulation techniques to
              dynamically update web pages, creating interactive and engaging
              user experiences.
            </li>
            <li>
              Extensive experience in utilizing API calls to retrieve and
              display data from databases, enhancing the functionality and
              content of web applications.{" "}
            </li>
            <li>
              Proficient in utilizing browser development tools to swiftly
              identify and resolve issues, ensuring seamless user experiences
              and optimal performance.
            </li>
            <li>
              Contributed to the development and implementation of responsive
              user interfaces using a combination of React, JavaScript, CSS, and
              HTML, delivering visually appealing and adaptable designs across
              multiple devices.
            </li>
            <li>
              Adept at optimizing code for enhanced performance, including
              minimizing load times and improving overall responsiveness to
              provide smooth and efficient user interactions.
            </li>
          </ul>

          <h4>Personal website - React, SQL, C#, .NET, Python</h4>
          <ul className="professional-experience-list">
            <li>
              Accomplished the development of a personal website from inception,
              showcasing a comprehensive portfolio of self-created projects.
            </li>
            <li>
              Implemented a robust back-end using C# in Visual Studio, providing
              seamless database interaction for {" "}
              <span onClick={goToWorkoutData} className="clickable-text">
               workout data storage.
              </span>
            </li>
            <li>
              Activated API endpoints to facilitate dynamic data retrieval and
              addition to the database.
            </li>
            <li>
              Utilized Python to develop a,{" "}
              <span onClick={goToBooksToScrape} className="clickable-text">
              custom data scraping script
              </span>{" "}
              automating the extraction of valuable information from online
              sources.
            </li>
            <li>
              Developed and maintained a 
              {" "}
              <span onClick={goToChessTournament} className="clickable-text">
              comprehensive tournament management system
              </span>{" "}
              system using Python, streamlining the process of organizing,
              scheduling, and tracking chess tournaments.
            </li>
            <li>
              Implemented advanced features in a tournament management
              application, including player registration, match scheduling, and
              real-time ranking updates, enhancing user experience and
              operational efficiency.
            </li>
          </ul>

          <h4>
            US Air Force - Air Transportation Supervisor (Jun 2018 - Jul 2022)
          </h4>
          <ul className="professional-experience-list">
            <li>
              Steered the 2nd Airborne Division's rapid deployment, surging
              3,500 personnel on seventy-two missions in less than two weeks,
              staging critical American assets in the Middle East amid Iranian
              aggression.
            </li>
            <li>
              Supervise use of materials, handling equipment, cargo for loading
              and unloading achieved a 99% safety compliance rate through
              training and oversight.{" "}
            </li>
            <li>
              Safeguarded crucial Special Operations Command movements by
              facilitating the placement of 26,000 operators into three areas of
              responsibility.{" "}
            </li>
            <li>
              Plans, organizes, directs, air transportation movements overseeing
              the successful completion of over 120 flights a month.
            </li>
            <li>
              Prepared, completed air movement records, documents, and reports.
              Establishes procedures for air clearance through international
              borders, reducing time by 30%.
            </li>
          </ul>

          
        </div>

        <div>
          <AboutMeUpdatesApp />
        </div>
      </div>
      
    </React.Fragment>
  );
}
export default Home;
