import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import "./admindashboard.css";

function AdminDashboardApp() {
  const [selectedConference, setSelectedConference] = useState("total");
  const [combinedData, setCombinedData] = useState([]);
  const [conferenceDetails, setConferenceDetails] = useState({
    american: {
      totalPlayers: 500,
      totalInjured: 50,
      totalReferees: 75,
      onCallReferees: 20,
    },
    national: {
      totalPlayers: 450,
      totalInjured: 45,
      totalReferees: 70,
      onCallReferees: 25,
    },
    total: {
      totalPlayers: 0,
      totalInjured: 0,
      totalReferees: 0,
      onCallReferees: 0,
    },
  });

  const moneyDetails = {
    revenue: 1000000,
    sales: 500000,
    totalSubscriptions: 10000,
  };

  const monthlyData = {
    american: [
      { month: "January", totalPlayers: 713, injuredPlayers: 10 },
      { month: "February", totalPlayers: 798, injuredPlayers: 14 },
      { month: "March", totalPlayers: 810, injuredPlayers: 19 },
      { month: "April", totalPlayers: 789, injuredPlayers: 10 },
      { month: "May", totalPlayers: 813, injuredPlayers: 14 },
      { month: "June", totalPlayers: 786, injuredPlayers: 19 },
      { month: "July", totalPlayers: 791, injuredPlayers: 10 },
      { month: "August", totalPlayers: 821, injuredPlayers: 14 },
      { month: "September", totalPlayers: 793, injuredPlayers: 19 },
      { month: "October", totalPlayers: 782, injuredPlayers: 10 },
      { month: "November", totalPlayers: 717, injuredPlayers: 14 },
      { month: "December", totalPlayers: 790, injuredPlayers: 19 },
    ],
    national: [
      { month: "January", totalPlayers: 780, injuredPlayers: 10 },
      { month: "February", totalPlayers: 791, injuredPlayers: 14 },
      { month: "March", totalPlayers: 762, injuredPlayers: 19 },
      { month: "April", totalPlayers: 821, injuredPlayers: 10 },
      { month: "May", totalPlayers: 801, injuredPlayers: 14 },
      { month: "June", totalPlayers: 781, injuredPlayers: 19 },
      { month: "July", totalPlayers: 763, injuredPlayers: 10 },
      { month: "August", totalPlayers: 702, injuredPlayers: 14 },
      { month: "September", totalPlayers: 810, injuredPlayers: 19 },
      { month: "October", totalPlayers: 762, injuredPlayers: 10 },
      { month: "November", totalPlayers: 781, injuredPlayers: 14 },
      { month: "December", totalPlayers: 731, injuredPlayers: 19 },
    ],
  };
//comment here
  const financialData = [
    {
      month: "September",
      revenue: 300000,
      sales: 150000,
      totalSubscriptions: 3000,
    },
    {
      month: "October",
      revenue: 350000,
      sales: 175000,
      totalSubscriptions: 3200,
    },
    {
      month: "November",
      revenue: 400000,
      sales: 200000,
      totalSubscriptions: 3500,
    },
  ];

  const renderFinancialChart = (dataKey, color) => (
    <BarChart
      width={500}
      height={300}
      data={financialData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={dataKey} fill={color} />
    </BarChart>
  );

  useEffect(() => {
    let newCombinedData = [];
    for (let conference in monthlyData) {
      monthlyData[conference].forEach((data) => {
        let existing = newCombinedData.find((d) => d.month === data.month);
        if (existing) {
          existing.totalPlayers += data.totalPlayers;
          existing.injuredPlayers += data.injuredPlayers;
        } else {
          newCombinedData.push({ ...data, month: data.month });
        }
      });
    }
    setCombinedData(newCombinedData);

    // Update total counts in conferenceDetails
    setConferenceDetails((prevDetails) => ({
      ...prevDetails,
      total: {
        totalPlayers:
          prevDetails.american.totalPlayers + prevDetails.national.totalPlayers,
        totalInjured:
          prevDetails.american.totalInjured + prevDetails.national.totalInjured,
        totalReferees:
          prevDetails.american.totalReferees +
          prevDetails.national.totalReferees,
        onCallReferees:
          prevDetails.american.onCallReferees +
          prevDetails.national.onCallReferees,
      },
    }));
  }, []);

  const selectConference = (conference) => {
    setSelectedConference(conference);
  };

  return (
    <React.Fragment>
      <div className="dashboard-title">Admin Dashboard</div>
      <div className="dashboard-sub-title">
        !!Not actual Admin Dashboard used in production!!
      </div>

      <div className="conference-container">
        <div
          className={`american-conference-card ${
            selectedConference === "american" ? "active" : ""
          }`}
          onClick={() => selectConference("american")}
        >
          American Conference
        </div>
        <div
          className={`national-conference-card ${
            selectedConference === "national" ? "active" : ""
          }`}
          onClick={() => selectConference("national")}
        >
          National Conference
        </div>
        <div
          className={`total-conference-card ${
            selectedConference === "total" ? "active" : ""
          }`}
          onClick={() => selectConference("total")}
        >
          Total
        </div>
        <div
          className={`sales-conference-card ${
            selectedConference === "revenueSales" ? "active" : ""
          }`}
          onClick={() => selectConference("revenueSales")}
        >
          Revenue/Sales
        </div>
      </div>

      <div className="players-container">
        {selectedConference === "revenueSales" ? (
          <>
            <div
              className={`player-card ${
                selectedConference === "revenueSales" ? "sales-active" : ""
              }`}
            >
              Revenue: ${moneyDetails.revenue}
            </div>
            <div
              className={`player-card ${
                selectedConference === "revenueSales" ? "sales-active" : ""
              }`}
            >
              Sales: ${moneyDetails.sales}
            </div>
            <div
              className={`player-card ${
                selectedConference === "revenueSales" ? "sales-active" : ""
              }`}
            >
              Total Subscriptions: {moneyDetails.totalSubscriptions}
            </div>
          </>
        ) : (
          <>
            <div
              className={`player-card ${
                selectedConference === "american"
                  ? "american-active"
                  : selectedConference === "national"
                  ? "national-active"
                  : selectedConference === "total"
                  ? "total-active"
                  : ""
              }`}
            >
              Total Players:{" "}
              {conferenceDetails[selectedConference].totalPlayers}
            </div>
            <div
              className={`player-card ${
                selectedConference === "american"
                  ? "american-active"
                  : selectedConference === "national"
                  ? "national-active"
                  : selectedConference === "total"
                  ? "total-active"
                  : ""
              }`}
            >
              Total Injured:{" "}
              {conferenceDetails[selectedConference].totalInjured}
            </div>
            <div
              className={`player-card ${
                selectedConference === "american"
                  ? "american-active"
                  : selectedConference === "national"
                  ? "national-active"
                  : selectedConference === "total"
                  ? "total-active"
                  : ""
              }`}
            >
              Total Referees:{" "}
              {conferenceDetails[selectedConference].totalReferees}
            </div>
            <div
              className={`player-card ${
                selectedConference === "american"
                  ? "american-active"
                  : selectedConference === "national"
                  ? "national-active"
                  : selectedConference === "total"
                  ? "total-active"
                  : ""
              }`}
            >
              On Call Referees:{" "}
              {conferenceDetails[selectedConference].onCallReferees}
            </div>
          </>
        )}
      </div>

      <div className="chart-container">
        {selectedConference === "revenueSales" ? (
          <div className="financial-charts">
            <div className="financial-chart">
              <h3>Monthly Revenue</h3>
              {renderFinancialChart("revenue", "#8884d8")}
            </div>
            <div className="financial-chart">
              <h3>Monthly Sales</h3>
              {renderFinancialChart("sales", "#82ca9d")}
            </div>
            <div className="financial-chart">
              <h3>Total Subscriptions</h3>
              {renderFinancialChart("totalSubscriptions", "#ffc658")}
            </div>
          </div>
        ) : (
          <BarChart
            width={1100}
            height={300}
            data={
              selectedConference === "total"
                ? combinedData
                : monthlyData[selectedConference]
            }
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalPlayers" stackId="a" fill="#038003" />
            <Bar dataKey="injuredPlayers" stackId="a" fill="#ff0000" />
          </BarChart>
        )}
      </div>
    </React.Fragment>
  );
}

export default AdminDashboardApp;
