import React, { useState, useEffect } from "react";
import "../gymCss/stopwatch.css";

const Stopwatch = () => {
  const [running, setRunning] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);

  useEffect(() => {
    let timer;

    if (running) {
      timer = setInterval(() => {
        setMilliseconds((prevMilliseconds) => prevMilliseconds + 100);

        if (milliseconds >= 1000) {
          setSeconds((prevSeconds) => prevSeconds + 1);
          setMilliseconds(0);
        }

        if (seconds >= 60) {
          setMinutes((prevMinutes) => prevMinutes + 1);
          setSeconds(0);
        }
      }, 100);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [running, seconds, milliseconds]);

  const startStopwatch = () => {
    setRunning(true);
  };

  const stopStopwatch = () => {
    setRunning(false);
  };

  const resetStopwatch = () => {
    setRunning(false);
    setMinutes(0);
    setSeconds(0);
    setMilliseconds(0);
  };

  return (
    <div className="stopwatch-container">
      <div className="stopwatch-display">
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}:
        {milliseconds.toString().padStart(3, "0")}
      </div>
      <div className="text-center">
        <button onClick={startStopwatch} className="stopwatch-button green-button">
          Start Stopwatch
        </button>
        <button onClick={stopStopwatch} className="stopwatch-button red-button">
          Stop Stopwatch
        </button>
        <button onClick={resetStopwatch} className="stopwatch-button orange-button">
          Reset Stopwatch
        </button>
      </div>
    </div>
  );
};

export default Stopwatch;
