import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrowelBricks,
  faHelmetSafety,
} from "@fortawesome/free-solid-svg-icons";
import "./reacttwojobscss/reacttwojobsapp.css";
import axios from "axios";

function ReactTwoJobs() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:5000/reacttwojobs") 
      .then((response) => {
        console.log("this is the response.data", response.data);
        setJobs(response.data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <React.Fragment>
      <div className="ReactTwoJobs-container">
        <FontAwesomeIcon icon={faTrowelBricks} className="fa-icon" />
        React2Jobs under construction
        <FontAwesomeIcon icon={faHelmetSafety} className="fa-icon" />
      </div>
      <div>
    <h1>Job Postings</h1>
    <div className="job-postings">
        {jobs.map((job, index) => (
            <div key={index} className="job-card">
                <h2><a href={job.link} target="_blank" rel="noopener noreferrer">{job.title}</a></h2>
                <p className="job-location">Location: {job.location}</p>
                <p className="job-remote">Remote Work: {job.remote_work}</p>
                <div className="job-details">
                    <div className="job-you-have">
                        <h3>You Have:</h3>
                        <ul>
                            {job.you_have.map((item, idx) => (
                                <li key={idx}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="job-nice-to-have">
                        <h3>Nice to Have:</h3>
                        <ul>
                            {job.nice_to_have.map((item, idx) => (
                                <li key={idx}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>

      <div className="padding-buddy"></div>
    </React.Fragment>
  );
}

export default ReactTwoJobs;
