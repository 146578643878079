import axios from "axios";

var apiBaseUrl = "http://localhost:5083/api/widgets";

var widgetsEndpoints = {
  insert: "/insert",
  getAll: "/getall",
  signIn: "/signin",
};

const add = (payload) => {
  console.log("add is firing");
  const config = {
    method: "POST",
    url: apiBaseUrl + widgetsEndpoints.insert,
    data: payload,
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then((response) => {
    return { id: response.data.item, ...payload };
  });
};

const getAll = () => {
  console.log("get is firing");
  const config = {
    method: "GET",
    url: apiBaseUrl + widgetsEndpoints.getAll,
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config);
};

const signIn = (credentials) => {
  console.log("signIn is firing");
  const config = {
    method: "POST",
    url: apiBaseUrl + widgetsEndpoints.signIn,
    data: credentials,
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then((response) => {
    return response.data;
  });
};

// Exporting the service
const widgetsService = {
  add,
  getAll,
  signIn,
};

export default widgetsService;
