import React from "react";
import "./yeartrackercss/yeartrackerapp.css";

const YearlyTrackerApp = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = `YearlyTracker.xlsx`;
    link.href = "/YearlyTracker.xlsx";
    link.click();
  };
  return (
    <React.Fragment>
      <header className="app-header">
        <h2>Yearly Growth Tracker</h2>
        <p>
          I created this Yearly Goal Tracker using Google Sheets to better
          manage my daily, weekly, monthly goals
        </p>
        <p>I hope this can improve and help you take on this year</p>
        <p>Click below to download</p>
        <button
          className="download-button"
          onClick={handleDownload}
          aria-label="Download Yearly Tracker"
        >
          Download Yearly Tracker
        </button>
      </header>

      <div className="issues-wrapper">
        <div className="issue-container">
          <img
            className="screenshot-img"
            src={"/images/GTrackerSS1.PNG"}
            alt="Tracker Screenshot 1"
          />
          <div className="description-card">
            <div className="card-body">
              <p className="card-title">Daily Goal Section and Graph</p>
              <p>Step 1: Download and upload file to google drive</p>
              <p>Step 2: Click on "Data" -> "Data Validation"</p>
              <p>Step 3: "Add Rule"</p>
              <p>Step 4: Where it says "Apply to range" input B22:AF31</p>
              <p>Step 5: Change Criteria to Checkbox -> Done</p>
              <p>Step 6: Click on Chart then click 3 dots on top right</p>
              <p>Step 7: "Edit Chart" </p>
              <p>Step 8: remove duplicated Series click 3 dots -> "remove"</p>
              <p>Done with Daily Goal Section and Graph</p>
              <p1>Customization tips: </p1>
              <p2>To make the color in the chart more you </p2>
              <p3>
                Click anywhere in chart -> double click any bar -> Chart Editor
                will appear, change 'Fill Color' to your desire
              </p3>
            </div>
          </div>
        </div>

        <div className="issue-container">
          <img
            className="screenshot-img"
            src={"/images/GTrackerSS2.PNG"}
            alt="Tracker Screenshot 2"
          />
          <div className="description-card">
            <div className="card-body">
              <p className="card-title">Weekly and Monthly Goal</p>
              <p>Step 1: Click on "Data" -> "Data Validation"</p>
              <p>Step 2: "Add Rule"</p>
              <p>
                Step 3: Where it says "Apply to range" input B39:F48,K39:V48
              </p>
              <p>Step 4: Change Criteria to Checkbox -> Done</p>
            </div>
          </div>
        </div>

        <div className="issue-container">
          <img
            className="screenshot-img"
            src={"/images/GTrackerSS3.PNG"}
            alt="Tracker Screenshot 3"
          />
          <div className="description-card">
            <div className="card-body">
              <p className="card-title">Yearly Weight Tracker</p>
              <p>
                Here you might want to change the weight to be more realistic
              </p>
              <p>Step 1: Click on Chart</p>
              <p>Step 2: Double click 1-250</p>
              <p>Step 3: A Chart Editor will pop up</p>
              <p>
                Step 4: for Min/Max you should input realistic numbers depending
                on your weight, ex: Min = 150 Max = 220
              </p>
              <p>Congrats you're all done with your Goal Tracker</p>
            </div>
          </div>
        </div>
      </div>

      <div className="padding-buddy"></div>
    </React.Fragment>
  );
};

export default YearlyTrackerApp;
