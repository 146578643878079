import React from "react";
import "./updates.css";

function AboutMeUpdatesApp() {
  const UpdateCard = ({ date, description, routePath, link }) => (
    <div className="update-card">
      <div className="update-date">{date}</div>
      <div className="update-description">{description}</div>
      <div className="update-route">{routePath}</div>
      <a href={link} className="update-link">
        View Project
      </a>
    </div>
  );

  const updates = [
    {
      date: "2 Feb 24",
      description: "Updated Chess Tournament",
      routePath: "Projects > Chess Tournament",
      link: "https://github.com/alexsoto562/ChessManagement",
    },
    {
      date: "11 Jan 24",
      description:
      "Started on job scraping project, goal scrape jobs from companies websites to help get a job",
      routePath: "Projects > React2Jobs",
      link: "/projects/react2jobs",
    },
    {
      date: "11 Jan 24",
      description: "Updated nutrition tracker",
      routePath: "Projects > GymApp > Track Food",
      link: "/gymapp/trackfood",
    },
    {
      date: "9 Jan 24",
      description: "Completed Growth Tracker",
      routePath: "Projects > Year Tracker",
      link: "/projects/yeartracker",
    },
    {
      date: "3 Jan 24",
      description: "Completed chess tournament",
      routePath: "Projects > Chess Tournament",
      link: "https://github.com/alexsoto562/ChessManagement",
    },
    {
      date: "26 Dec 23",
      description: "Started Growth Tracker",
      routePath: "Projects > Year Tracker",
      link: "/projects/yeartracker",
    },
    {
      date: "19 Dec 23",
      description: "Added sign in to connect to database",
      routePath: ">Sign In",
      link: "/signin",
    },
    {
      date: "12 Dec 23",
      description: "Added nutrition tracker",
      routePath: "Projects > GymApp > Track Food",
      link: "/gymapp/trackfood",
    },
  ];

  return (
    <div>
      <h2>Update Section</h2>
      <p>I am committed to do at least one push every week</p>
      <div className="updates-container">
        {updates.map((update, index) => (
          <UpdateCard
            key={index}
            date={update.date}
            description={update.description}
            routePath={update.routePath}
            link={update.link}
          />
        ))}
      </div>
    </div>
  );
}

export default AboutMeUpdatesApp;
