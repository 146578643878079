import React from "react";

const MealCard = ({
  mealType,
  food,
  setFood,
  weight,
  setWeight,
  unit,
  setUnit,
  mealData,
  currentMealNutrition,
  addFoodToMeal,
  calculateMealNutrition,
}) => {
  const mealTitle = mealType.charAt(0).toUpperCase() + mealType.slice(1);
  const isCurrentMeal =
    currentMealNutrition && currentMealNutrition.meal === mealType;

  return (
    <div className="meal-card">
      <h3>{mealTitle}</h3>
      <div className="meal-inputs">
        <label htmlFor={`${mealType}-food`} className="label">
          Food:
          <select
            id={`${mealType}-food`}
            className="select"
            value={food}
            onChange={(e) => setFood(e.target.value)}
          >
            <option value="">Select a food</option>
            <option value="chicken breast">Chicken Breast</option>
            <option value="80/20 beef">80/20 Beef</option>
            <option value="eggs">Eggs</option>
            <option value="brown rice">Brown Rice</option>
          </select>
        </label>
        <label htmlFor={`${mealType}-weight`} className="label">
          Weight:
          <input
            id={`${mealType}-weight`}
            type="number"
            className="input"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </label>
        <label htmlFor={`${mealType}-unit`} className="label">
          Unit:
          <select
            id={`${mealType}-unit`}
            className="select"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            <option value="grams">Grams</option>
            <option value="oz">Ounces</option>
          </select>
        </label>
        <button
          onClick={() => addFoodToMeal(mealType, food, weight, unit)}
          className="add-button"
        >
          Add to {mealType}
        </button>
      </div>
      {mealData[mealType].map((item, index) => (
        <p key={index}>
          {item.food}, {item.weight}
          {item.unit}: Protein: {item.protein.toFixed(2)}g, Carbs:
          {item.carbs.toFixed(2)}g, Fats: {item.fats.toFixed(2)}g, Calories:
          {item.calories.toFixed(2)}
        </p>
      ))}
      <button onClick={() => calculateMealNutrition(mealType)}>
        Add Total {mealType}
      </button>
      {isCurrentMeal && (
        <div className="current-meal-nutrition">
          <h3>{mealTitle} Total Nutrition</h3>
          <p>Protein: {currentMealNutrition.mealTotal.protein.toFixed(2)}g</p>
          <p>Carbs: {currentMealNutrition.mealTotal.carbs.toFixed(2)}g</p>
          <p>Fats: {currentMealNutrition.mealTotal.fats.toFixed(2)}g</p>
          <p>Calories: {currentMealNutrition.mealTotal.calories.toFixed(2)}</p>
        </div>
      )}
    </div>
  );
};

export default MealCard;