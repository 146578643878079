import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Stopwatch from "./StopWatch";
import toastr from "toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlus,
  faSave,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "../gymCss/gymapp.css";

const ArmDay = () => {

  const workoutOptions = [
    "Bicep Curls",
    "Tricep Dips",
    "Hammer Curls",
    "Push-Ups",
    "Overhead Tricep Extensions",
    "Ez Curls",
  ];
  
  const navigate = useNavigate();
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [workoutData, setWorkoutData] = useState([]);
  const [newWorkoutName, setNewWorkoutName] = useState("");

  const selectWorkout = (workoutType) => {
    setSelectedWorkouts([...selectedWorkouts, workoutType]);
    setWorkoutData((prevData) => [
      ...prevData,
      {
        type: workoutType,
        sets: [{ reps: 0, weight: 0 }],
      },
    ]);
  };

  const updateWorkoutInput = (workoutIndex, setIndex, field, value) => {
    value = Math.max(0, parseInt(value, 10));
    const updatedData = [...workoutData];
    updatedData[workoutIndex].sets[setIndex][field] = value;
    setWorkoutData(updatedData);
  };

  const addSetToWorkout = (workoutIndex) => {
    const updatedData = [...workoutData];
    updatedData[workoutIndex].sets.push({ reps: 0, weight: 0 });
    setWorkoutData(updatedData);
  };

  const saveAllWorkouts = () => {
    toastr.error("Data not saved, no database connected");
  };

  const deleteWorkout = (workoutIndex) => {
    const updatedSelectedWorkouts = [...selectedWorkouts];
    updatedSelectedWorkouts.splice(workoutIndex, 1);

    const updatedWorkoutData = [...workoutData];
    updatedWorkoutData.splice(workoutIndex, 1);

    setSelectedWorkouts(updatedSelectedWorkouts);
    setWorkoutData(updatedWorkoutData);
  };

  const handleMainMenu = () => {
    navigate("/projects/gymapp");
  };

  const addCustomWorkout = () => {
    if (newWorkoutName.trim() === "") {
      console.log("Please enter a workout name.");
      return;
    }

    setSelectedWorkouts([...selectedWorkouts, newWorkoutName]);
    setWorkoutData((prevData) => [
      ...prevData,
      {
        type: newWorkoutName,
        sets: [{ reps: 0, weight: 0 }],
      },
    ]);
    setNewWorkoutName("");
  };

  return (
    <React.Fragment>
      <div className="main-container">
        <button className="btn main-menu-btn" onClick={handleMainMenu}>
          <FontAwesomeIcon icon={faHome} /> Main Menu
        </button>

        <Stopwatch />

        <section>
          <h3>Select workouts:</h3>
          <div className="gym-card-container">
            {workoutOptions.map((workoutType, index) => (
              <div
                key={index}
                className="gym-card"
                onClick={() => selectWorkout(workoutType)}
              >
                <h4>{workoutType}</h4>
              </div>
            ))}
            <div className="gym-card">
              <input
                type="text"
                placeholder="Enter custom workout name"
                value={newWorkoutName}
                onChange={(e) => setNewWorkoutName(e.target.value)}
              />
              <button
                className="btn add-workout-btn"
                onClick={addCustomWorkout}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Custom Workout
              </button>
            </div>
          </div>
        </section>

        <section>
          <h3>Selected Workouts:</h3>
          <button className="btn save-all-btn" onClick={saveAllWorkouts}>
            <FontAwesomeIcon icon={faSave} /> Save All Workouts
          </button>

          <div className="gym-card-container">
            {selectedWorkouts.map((workoutType, workoutIndex) => (
              <div key={workoutIndex} className="gym-card">
                <h4>{workoutType}</h4>
                {workoutData[workoutIndex].sets.map((set, setIndex) => (
                  <div key={setIndex}>
                    <label>Set {setIndex + 1}:</label>
                    <input
                      type="number"
                      value={set.reps || 0}
                      onChange={(e) =>
                        updateWorkoutInput(
                          workoutIndex,
                          setIndex,
                          "reps",
                          e.target.value
                        )
                      }
                    />
                    <label>Weight:</label>
                    <input
                      type="number"
                      value={set.weight || 0}
                      onChange={(e) =>
                        updateWorkoutInput(
                          workoutIndex,
                          setIndex,
                          "weight",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
                <div className="workout-controls">
                  <button
                    className="btn add-set-btn"
                    onClick={() => addSetToWorkout(workoutIndex)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Set
                  </button>

                  <button
                    className="btn delete-workout-btn"
                    onClick={() => deleteWorkout(workoutIndex)}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete Workout
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
export default ArmDay;
