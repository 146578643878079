import React, { useState } from "react";
import widgetsService from "./widgetsService";

function InsertWidgets() {
  /* manually add info to database
//   const widgetData = {
//     name: "vs code test 2",
//     manufacturer: "vs code WidgetCorp",
//     description: "A sample widget description",
//     cost: 299,
//   };

//   const addButton = (e) => {
//     e.preventDefault();
//     console.log("add button was clicked");
//     widgetsService
//     .add(widgetData)
//     .then(onAddSuccess)
//     .catch(onAddError);
*/

  const [widgetData, setWidgetData] = useState({
    name: "",
    manufacturer: "",
    description: "",
    cost: 0,
  });

  const [widgets, setWidgets] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWidgetData({ ...widgetData, [name]: value });
  };

  const addButton = (e) => {
    e.preventDefault();
    console.log("add button was clicked", widgetData);
    widgetsService
    .add(widgetData)
    .then(onAddSuccess)
    .catch(onAddError);
  };

  const onAddSuccess = (response) => {
    console.log("ADD submit success", response);
  };

  const onAddError = (response) => {
    console.warn({ error: response });
    console.log("onSubmitError firing", response);
  };

  const getAllButton = (e) => {
    e.preventDefault();
    console.log("getAllButton was clicked");
    widgetsService
    .getAll()
    .then(onGetAllSuccess)
    .catch(onGetAllError);
  };

  const onGetAllSuccess = (response) => {
    console.log("this is the get all response", response.data);
    setWidgets(response.data);
  };

  const onGetAllError = (response) => {
    console.warn({ error: response });
    console.log("error is firing");
  };

  return (
    <React.Fragment>
      <div>
        <h1>Widget Creator</h1>
        <form onSubmit={addButton}>
          <input
            type="text"
            name="name"
            value={widgetData.name}
            onChange={handleChange}
            placeholder="Name"
          />
          <input
            type="text"
            name="manufacturer"
            value={widgetData.manufacturer}
            onChange={handleChange}
            placeholder="Manufacturer"
          />
          <textarea
            name="description"
            value={widgetData.description}
            onChange={handleChange}
            placeholder="Description"
          />
          <input
            type="number"
            name="cost"
            value={widgetData.cost}
            onChange={handleChange}
            placeholder="Cost"
          />
          <button type="submit">Insert Widget</button>
        </form>
        <button onClick={getAllButton}>Get All</button>
      </div>

      <div>
        <h2>Widgets Cards</h2>
        {widgets.map((widget, index) => (
          <div key={index}>
            <h3>{widget.name}</h3>
            <p>Manufacturer: {widget.manufacturer}</p>
            <p>Description: {widget.description}</p>
            <p>Cost: ${widget.cost}</p>
          </div>
        ))}
      </div>

    </React.Fragment>
  );
}

export default InsertWidgets;
