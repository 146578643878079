import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css';

const ProjectCard = ({ icon, title, description, link }) => {
  const isExternalLink = link && link.startsWith('http');

  return (
    <div className="project-card">
      <img src={icon} alt={title} className="project-icon" />
     
      {isExternalLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="project-title-link">{title}</a>
      ) : (
        <Link to={link} className="project-title-link">{title}</Link>
      )}
      <p className="project-description">{description}</p>
    </div>
  );
};

const Projects = () => {
  const projects = [
    { icon: "./images/React-icon.svg.png", title: 'React2Jobs', description: 'Scrape Job Applications', link: '/projects/react2jobs' },
    { icon: "./images/React-icon.svg.png", title: 'Help and Support', description: 'Simple help and support page', link: '/projects/helpandsupport' },
    { icon: './images/React-icon.svg.png', title: 'Training Videos', description: 'Training video for referees', link: '/projects/trainingvideo' },
    { icon: './images/React-icon.svg.png', title: 'Admin Dashboard', description: 'Admin dashboard central', link: '/projects/admindashboard' },
    { icon: './images/barbell-icon.png', title: 'Gym App', description: 'Gym tracker / Food tracker', link: '/projects/gymapp' },
    { icon: "./images/React-icon.svg.png", title: 'Year Tracker', description: 'See personal growth', link: '/projects/yeartracker' },
    { icon: './images/python-icon.png', title: 'Books to Scrape', description: 'How to scrape data', link: 'https://github.com/alexsoto562/BooksToScrape' },
    { icon: './images/python-icon.png', title: 'Chess Tournament', description: 'App to help manage chess tournament', link: 'https://github.com/alexsoto562/ChessManagement' },
    { icon: './images/java-icon.png', title: 'Wind Chill Factor', description: 'Wind chill calculator', link: 'https://github.com/alexsoto562/WindChillCalculator' },
  ];

  return (
    <div className="projects-container">
      <h2>Projects I've worked on in various languages</h2>
      <p>I've worked on a variety of projects over the years and I'm proud of the progress I've made...</p>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default Projects;