import React, { useEffect, useState } from "react";
import axios from "axios";

function BookDataDisplay() {
  const [bookData, setBookData] = useState({});

  useEffect(() => {
    axios
      .get("http://localhost:5000/book-data") 
      .then((response) => {
        console.log("this is the response.data", response.data);
        setBookData(response.data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <React.Fragment>
      <div>BooksToScrape</div>
      <div className="Book-container" style={{paddingBottom:"200px"}}>

        <h1>Title: {bookData.Title}</h1>
        <h1>Category: {bookData.Category}</h1>
        <h1>Image Url: {bookData.Img_Url}</h1>
        <h1>Product Description: {bookData.ProductDescription}</h1>
        <h1>Book URL: {bookData.URL}</h1>
        <h1>Quantity: {bookData.Quantity}</h1>
        <h1>UPC: {bookData.UPC}</h1>

      </div>
    </React.Fragment>
  );
}

export default BookDataDisplay;