import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./footer.css";

function FooterAboutMe() {
  return (
    <footer>
      <nav>
        <ul className="navbar">
          <li>
            <a
              href="https://github.com/alexsoto562"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} /> GitHub
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/alejandro-soto562/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
            </a>
          </li>
          <li>
            <a href="mailto:sotoalex562@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} /> Email
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default FooterAboutMe;
