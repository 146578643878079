import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px'
};

const center = {
  lat: 33.8583,
  lng: -118.0648
};

function MyComponent() {
  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyCV3Te0D3ItaKMYcxEbyWhmdMufQMyIJm4"}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
      </GoogleMap>
    </LoadScript>
  )
}

export default MyComponent;
