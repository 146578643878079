import React from "react";
import "./contact.css";

function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-card">
        <div className="card-body">
          <div className="card-title contact-title">
            Let's Get in Touch: Ways to Connect with Me
          </div>
          <div className="card-text contact-text">
            Thank you for visiting my webpage! I sincerely hope that my
            showcased projects and the design of this page effectively
            illustrate the skills and dedication I can bring to any team. If
            you'd like to see my resume or discuss potential opportunities,
            please don't hesitate to reach out via email:{" "}
            <a href="mailto:sotoalex562@gmail.com" className="email-link">
              sotoalex562@gmail.com
            </a>
            . I am committed to responding within 24 hours. Once again, I
            appreciate your interest and am eager to connect and explore how we
            might collaborate. Looking forward to our conversation!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
