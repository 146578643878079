import React, { useState } from 'react';
import axios from 'axios';

const CreateTournamentForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        venue: '',
        start_date: '',
        end_date: '',
        max_round: '',
        // Add other fields as needed
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/create_tournament', formData);
            console.log(response.data);
            // Handle success (e.g., show message, clear form)
        } catch (error) {
            console.error("There was an error creating the tournament", error);
            // Handle error (e.g., show error message)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Tournament Name"
            />
            <input
                type="text"
                name="venue"
                value={formData.venue}
                onChange={handleInputChange}
                placeholder="Venue"
            />
            <input
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
            />
            <input
                type="date"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
            />
            <input
                type="number"
                name="max_round"
                value={formData.max_round}
                onChange={handleInputChange}
                placeholder="Max Rounds"
            />
            {/* Add other inputs as necessary */}
            <button type="submit">Create Tournament</button>
        </form>
    );
};

export default CreateTournamentForm;
