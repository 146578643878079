import React, { useState } from "react";
import "./signincss/signin.css";
import widgetsService from "../../Widgets/widgetsService";

function SignInApp() {
    const [signInData, setSignInData] = useState({
        username: "",
        password: ""
    });

    const [hoveredItem, setHoveredItem] = useState('');

    const translations = {
        username: "Nombre de usuario",
        password: "Contraseña"
    };

    const handleSignInInput = (e) => {
        const { name, value } = e.target;
        setSignInData({ ...signInData, [name]: value });
    };

    const signInButton = (e) => {
        e.preventDefault();
        console.log("sign in button was clicked", signInData);
        widgetsService
            .signIn(signInData)
            .then(onSignInSuccess)
            .catch(onSignInError);
    }

    const onSignInSuccess = (response) => {
        console.log("ADD submit success", response);
    };

    const onSignInError = (response) => {
        console.warn({ error: response });
        console.log("onSubmitError firing", response);
    };

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem('');
    };

    return (
        <div className="signin-container">
            <h1>Alex Soto's Website</h1>
            <form className="signin-form">
                <div className="input-group" 
                     onMouseEnter={() => handleMouseEnter('username')} 
                     onMouseLeave={handleMouseLeave}>
                    <label htmlFor="username">Username {hoveredItem === 'username' && `(${translations.username})`}</label>
                    <input 
                        type="text" 
                        id="username" 
                        name="username"
                        value={signInData.username}
                        onChange={handleSignInInput}
                        placeholder="username"
                        required />
                </div>
                <div className="input-group" 
                     onMouseEnter={() => handleMouseEnter('password')} 
                     onMouseLeave={handleMouseLeave}>
                    <label htmlFor="password">Password {hoveredItem === 'password' && `(${translations.password})`}</label>
                    <input 
                        type="password" 
                        id="password" 
                        name="password" 
                        value={signInData.password}
                        onChange={handleSignInInput}
                        placeholder="password"
                        required />
                </div>
                <button type="submit" onClick={signInButton}>Sign In</button>
            </form>
        </div>
    );
}

export default SignInApp;