// TrackFood.jsx
import React, { useState } from "react";
import toastr from "toastr";
import MealCard from "./MealCard"; // Adjust this import path as per your project structure
import "../gymCss/trackfood.css";

function TrackFood() {
  const [mealData, setMealData] = useState({
    breakfast: [],
    lunch: [],
    dinner: [],
  });

  const [dailyIntake, setDailyIntake] = useState({
    protein: 0,
    carbs: 0,
    fats: 0,
    calories: 0,
  });

  const [breakfastFood, setBreakfastFood] = useState("");
  const [lunchFood, setLunchFood] = useState("");
  const [dinnerFood, setDinnerFood] = useState("");
  const [breakfastWeight, setBreakfastWeight] = useState("");
  const [lunchWeight, setLunchWeight] = useState("");
  const [dinnerWeight, setDinnerWeight] = useState("");
  const [breakfastUnit, setBreakfastUnit] = useState("oz");
  const [lunchUnit, setLunchUnit] = useState("oz");
  const [dinnerUnit, setDinnerUnit] = useState("oz");
  const [currentMealNutrition, setCurrentMealNutrition] = useState(null);

  const NUTRITIONAL_VALUES = {
    "chicken breast": { protein: 31, carbs: 0, fats: 3.6, calories: 165 },
    "80/20 beef": { protein: 20, carbs: 0, fats: 15, calories: 254 },
    "eggs": { protein: 13, carbs: 1.1, fats: 11, calories: 155 },
    "brown rice": { protein: 2.6, carbs: 23, fats: 1, calories: 111 },
  };

  const convertToGrams = (weight, unit) => {
    return unit === "oz" ? weight * 28.3495 : weight;
  };

  const calculateNutrition = (food, weight, unit) => {
    if (food && weight && NUTRITIONAL_VALUES[food]) {
      const weightInGrams = convertToGrams(weight, unit);
      const factor = weightInGrams / 100;
      const foodValues = NUTRITIONAL_VALUES[food];
      return {
        protein: foodValues.protein * factor,
        carbs: foodValues.carbs * factor,
        fats: foodValues.fats * factor,
        calories: foodValues.calories * factor,
      };
    }
    return null;
  };

  const addFoodToMeal = (mealType, food, weight, unit) => {
    const nutrition = calculateNutrition(food, weight, unit);
    if (nutrition) {
      setMealData((prevData) => ({
        ...prevData,
        [mealType]: [
          ...prevData[mealType],
          { food, weight, unit, ...nutrition },
        ],
      }));
      calculateTotalNutrition();
    }
  };

  const calculateTotalNutrition = () => {
    let total = { protein: 0, carbs: 0, fats: 0, calories: 0 };
    Object.values(mealData)
      .flat()
      .forEach((item) => {
        total.protein += item.protein;
        total.carbs += item.carbs;
        total.fats += item.fats;
        total.calories += item.calories;
      });
    setDailyIntake(total);
  };

  const calculateMealNutrition = (meal) => {
    let mealTotal = { protein: 0, carbs: 0, fats: 0, calories: 0 };
    mealData[meal].forEach((item) => {
      mealTotal.protein += item.protein;
      mealTotal.carbs += item.carbs;
      mealTotal.fats += item.fats;
      mealTotal.calories += item.calories;
    });
    setCurrentMealNutrition({ meal, mealTotal });
  };

  const onSaveIntake = () => {
    toastr.error("no database connected");
  };

  return (
    <React.Fragment>
      <div className="track-food-container">
        <h1>Track Food Intake</h1>
        <div className="meal-cards-container">
          <MealCard
            mealType="breakfast"
            food={breakfastFood}
            setFood={setBreakfastFood}
            weight={breakfastWeight}
            setWeight={setBreakfastWeight}
            unit={breakfastUnit}
            setUnit={setBreakfastUnit}
            currentMealNutrition={currentMealNutrition}
            mealData={mealData}
            addFoodToMeal={addFoodToMeal}
            calculateMealNutrition={calculateMealNutrition}
          />
          <MealCard
            mealType="lunch"
            food={lunchFood}
            setFood={setLunchFood}
            weight={lunchWeight}
            setWeight={setLunchWeight}
            unit={lunchUnit}
            setUnit={setLunchUnit}
            currentMealNutrition={currentMealNutrition}
            mealData={mealData}
            addFoodToMeal={addFoodToMeal}
            calculateMealNutrition={calculateMealNutrition}
          />
          <MealCard
            mealType="dinner"
            food={dinnerFood}
            setFood={setDinnerFood}
            weight={dinnerWeight}
            setWeight={setDinnerWeight}
            unit={dinnerUnit}
            setUnit={setDinnerUnit}
            currentMealNutrition={currentMealNutrition}
            mealData={mealData}
            addFoodToMeal={addFoodToMeal}
            calculateMealNutrition={calculateMealNutrition}
          />
          <div className="total-intake-card">
            <h2>Today's Total Intake</h2>
            <p>Protein: {dailyIntake.protein.toFixed(2)}g</p>
            <p>Carbs: {dailyIntake.carbs.toFixed(2)}g</p>
            <p>Fats: {dailyIntake.fats.toFixed(2)}g</p>
            <p>Calories: {dailyIntake.calories.toFixed(2)}</p>
            <button type="success" onClick={onSaveIntake}>
              Save Intake
            </button>
          </div>
        </div>
      </div>
      <div className="padding-bottom"></div>
    </React.Fragment>
  );
}

export default TrackFood;