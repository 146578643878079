import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutMe from "./AboutMeWebsite/AboutMe";
import AboutMeNav from "./AboutMeWebsite/AboutMeNav";
import Projects from "./AboutMeWebsite/Projects"
import Contact from "./AboutMeWebsite/Contact"
import Home from "./AboutMeWebsite/Home";
import "./AboutMeWebsite/AboutMeNav.css";
import TrainingVideoApp from "./TrainingVideo/TrainingVideoApp";
import HelpAndSupportMain from "./HelpAndSupportPage/HelpAndSupportMain"
import FooterAboutMe from "./AboutMeWebsite/FooterAboutMe";
import AdminDashboardApp from "./AdminDashboard/AdminDashboardApp";
import GymHomePage from "./gymApp/GymMain";
import ArmDay from "./gymApp/gymJsx/ArmsDay";
import ChestDay from "./gymApp/gymJsx/ChestDay";
import BackDay from "./gymApp/gymJsx/BackDay";
import LegDay from "./gymApp/gymJsx/LegDay"
import CardioDay from "./gymApp/gymJsx/CardioDay";
import CoreDay from "./gymApp/gymJsx/CoreDay";
import TrackFood from "./gymApp/gymJsx/TrackFood";
import ChessApp from "./ChessTournament/ChessApp"
import InsertWidgets from "./Widgets/InsertWidgets";
import PizzaDelivery from "./PizzaDelivery/PizzaDeliveryApp"
import SignInApp from "./AboutMeWebsite/SignIn/SignInApp";
import BooksToScrapeApp from "./BooksToScrape/BooksToScrapeApp"
import YearTrackerApp from "./YearTracker/YearTrackerApp";
import ReactTwoJobs from "./ReactTwoJobs/ReactTwoJobsApp";



function App() {
  return (
    <React.Fragment>
      <AboutMeNav />
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/aboutme" element={<AboutMe />}></Route>
        <Route path="/projects" element={<Projects/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/projects/trainingvideo" element={<TrainingVideoApp/>}></Route>
        <Route path="/projects/helpandsupport" element={<HelpAndSupportMain/>}></Route>
        <Route path="/projects/admindashboard" element={<AdminDashboardApp/>}></Route>
        <Route path="/projects/gymapp" element={<GymHomePage/>}></Route>
        <Route path="/projects/yeartracker" element={<YearTrackerApp/>}></Route>
        <Route path="/gymapp/chestday" element={<ChestDay/>}></Route>
        <Route path="/gymapp/armsday" element={<ArmDay/>}></Route>
        <Route path="/gymapp/backday" element={<BackDay/>}></Route>
        <Route path="/gymapp/legday" element={<LegDay/>}></Route>
        <Route path="/gymapp/cardioday" element={<CardioDay/>}></Route>
        <Route path="/gymapp/coreday" element={<CoreDay/>}></Route>
        <Route path="/gymapp/trackfood" element={<TrackFood/>}></Route>
        <Route path="/projects/chessmanager" element={<ChessApp/>}></Route>
        <Route path="/widgets" element={<InsertWidgets/>}></Route>
        <Route path="/pizzadelivery" element={<PizzaDelivery/>}></Route>
        <Route path="/signin" element={<SignInApp/>}></Route>
        <Route path="/bookstoscrape" element={<BooksToScrapeApp/>}></Route>
        <Route path="/projects/react2jobs" element={<ReactTwoJobs/>}></Route>
      </Routes>
      <FooterAboutMe/>
    </React.Fragment>
  );
}
export default App;