import React, { useState } from "react";
import toastr from "toastr";
import "./chatwithsupport.css";

function ChatWithSupport() {
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");

  const handleChatSubmit = (event) => {
    event.preventDefault();
    if (chatInput.trim() === "") return;

    setChatMessages([...chatMessages, { text: chatInput, sender: "user" }]);
    setChatInput("");

    setTimeout(() => {
      setChatMessages((messages) => [
        ...messages,
        {
          text: "Thank you for your message, how can we assist you further?",
          sender: "support",
        },
      ]);
    }, 1000);
  };

  const contactUsSendButton = () => {
    toastr.error("Server not connected, not chat support available");
  };

  return (
    <div className="card chat-section">
      {/* Placeholder for icon */}
      <div className="card-icon">💬</div>
      <h2>Chat with Support</h2>

      <div className="chat-container">
        <form onSubmit={handleChatSubmit}>
          <input
            type="text"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            placeholder="Type your message here..."
          />
          <button type="submit" onClick={contactUsSendButton}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatWithSupport;
