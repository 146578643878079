import React from "react";
import { useNavigate } from "react-router-dom";
import "./gymCss/gymmain.css";
import PrMainGraphs from "./gymJsx/PrMainGraphs";


const cards = [
  { title: "Chest", url: "/gymapp/chestday" },
  { title: "Arms", url: "/gymapp/armsday" },
  { title: "Back", url: "/gymapp/backday" },
  { title: "Leg", url: "/gymapp/legday" },
  { title: "Cardio", url: "/gymapp/cardioday" },
  { title: "Core/abs", url: "/gymapp/coreday" },
  { title: "Track Food", url: "/gymapp/trackfood" },
];

function GymHomePage() {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    navigate(url);
  };

  return (
    <div className="gym-container">
      <h2>Gym Day!</h2>
      <p>Welcome to my Gym App!</p>
      <div className="gym-grid">
        {cards.map((card, index) => (
          <div className="card-maingym" key={index} onClick={() => handleCardClick(card.url)}>

            <div>
              <h3>{card.title}</h3>

            </div>
          </div>
        ))}
      </div>
      <PrMainGraphs/>
    </div>
  );
}

export default GymHomePage;
