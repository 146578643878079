import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../gymCss/prgraph.css";

function PrMainGraphs() {
  const data = [
    {
      name: "WK 1",
      maxBench: 95,
      maxSquat: 45,
      maxDeadlift: 135,
      runTime: 15.2,
    },
    {
      name: "WK 2",
      maxBench: 105,
      maxSquat: 135,
      maxDeadlift: 135,
      runTime: 14.6,
    },
    {
      name: "WK 3",
      maxBench: 135,
      maxSquat: 150,
      maxDeadlift: 185,
      runTime: 15.1,
    },
    {
      name: "WK 4",
      maxBench: 155,
      maxSquat: 160,
      maxDeadlift: 225,
      runTime: 14.2,
    },
    {
      name: "WK 5",
      maxBench: 175,
      maxSquat: 150,
      maxDeadlift: 275,
      runTime: 14.2,
    },
    {
      name: "WK 6",
      maxBench: 195,
      maxSquat: 100,
      maxDeadlift: 275,
      runTime: 13.9,
    },
    {
      name: "WK 7",
      maxBench: 195,
      maxSquat: 150,
      maxDeadlift: 135,
      runTime: 13.1,
    },
    {
      name: "WK 8",
      maxBench: 180,
      maxSquat: 225,
      maxDeadlift: 135,
      runTime: 12.8,
    },
    {
      name: "WK 9",
      maxBench: 200,
      maxSquat: 275,
      maxDeadlift: 275,
      runTime: 12.5,
    },
    {
      name: "WK 10",
      maxBench: 205,
      maxSquat: 315,
      maxDeadlift: 315,
      runTime: 12.1,
    },
    {
      name: "WK 11",
      maxBench: 215,
      maxSquat: 360,
      maxDeadlift: 405,
      runTime: 11.9,
    },
    {
      name: "WK 12",
      maxBench: 225,
      maxSquat: 405,
      maxDeadlift: 405,
      runTime: 11,
    },
  ];

  return (
    <React.Fragment>
      <div className="pr-title">PRs from last 12 weeks</div>
      <div className="pr-graphs-container">
        <div className="graph-card-bench">
          <h3>Max Bench</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="maxBench" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="graph-card-squat">
          <h3>Max Squat</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="maxSquat" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="graph-card-deadlift">
          <h3>Max Deadlift</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="maxDeadlift" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="graph-card-run">
          <h3>Run Time</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="runTime" stroke="#ff82a9" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PrMainGraphs;
